import BaseModal from "components/BaseModal";
import React, { useState } from "react";

const FailBlock = ({
  question,
  images = [],
  note = "",
  handleChange,
  handleImageChange,
  canSaveInspection,
  handleRemoveImage,
  isOwner,
}) => {
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [image, setImage] = useState("");
  const onOpenPreviewModal = (image) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenPreviewModal(true);
    setImage(image);
  };
  const onClosePreviewModal = () => {
    setOpenPreviewModal(false);
    setImage("");
  };

  return (
    <>
      <div className="pt-4">
        {canSaveInspection && (
          <div className="form-floating mb-4 go-bottom">
            <textarea
              required
              className="form-control inspection-note-textarea"
              name="note"
              id="inspection note"
              rows={3}
              value={note}
              onChange={handleChange(question.id, "note")}
              placeholder="Inspection notes"
            ></textarea>
            <label htmlFor="name">Inspection notes</label>
          </div>
        )}
        {!canSaveInspection && note && (
          <div>
            <h6>Note</h6>
            <p>{note}</p>
          </div>
        )}
        {canSaveInspection && (
          <div className="d-flex justify-content-between pb-4 align-items-center">
            <div className="section-header">
              <h6 className="section-image-title">Inspection Images</h6>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <input
                multiple
                type="file"
                name="questionResultImage"
                onChange={handleImageChange(question.id)}
                id={`select-image-${question.id}`}
                className="btn btn-outline-secondary"
                style={{ display: "none" }}
              />
              <label
                htmlFor={`select-image-${question.id}`}
                className="btn btn-outline-secondary px-4 py-2 d-flex align-items-center text-uppercase"
              >
                Select images
              </label>
            </div>
          </div>
        )}

        {images.length > 0 && (
          <div className="section-image-holder mb-4">
            <div
              style={{ marginTop: "-8px", marginLeft: "-8px" }}
              className="d-flex flex-wrap justify-content-lg-start justify-content-center"
            >
              {images.map((image, i) => (
                <div
                  key={`questionImage${image.image}${question.id}${i}`}
                  className="item-result-image position-relative mt-2 ms-2"
                >
                  <img
                    src={image.image}
                    onClick={onOpenPreviewModal(image.image)}
                    alt="Question Result Image"
                    className="image-responsive section-image ms-0 me-0"
                    style={{ minWidth: "150px" }}
                  />
                  {isOwner && (
                    <a
                      href="#"
                      onClick={handleRemoveImage(question.id, i)}
                      className="position-absolute top-0 end-0 me-2 mt-1 shadow-sm rounded-circle overflow-hidden"
                    >
                      <i
                        className="fa-sharp fa-solid fa-circle-xmark rounded-circle"
                        style={{ background: "#fff" }}
                      ></i>
                    </a>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {openPreviewModal && (
        <BaseModal
          onClose={onClosePreviewModal}
          show={openPreviewModal}
          className="text-center"
          size="xl"
          formWrapper={false}
          showFooter={false}
          headerProps={{
            closeButton: true,
            className:
              "pt-0 position-absolute mb-0 top-0 end-0 mt-3 me-0 cursor-pointer",
            style: { zIndex: 10 },
          }}
          content={
            <img
              className="img-fluid mt-2 mt-lg-0"
              src={image}
              alt="Map Image Preview"
              style={{ maxHeight: "80vh" }}
            />
          }
        />
      )}
    </>
  );
};

export default FailBlock;
