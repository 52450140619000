import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import OverviewMainContent from "../../components/Dashboard/OverviewComponents/OverviewMainContent";
import { getUserDetails } from "../../services/auth";

import "./overview.scss";
import "../Dashboard/dashboard.scss";
import "../Dashboard/margin_and_padding_helpers.scss";
import { fetchSections } from "../../services/section";
import InnerLoading from "components/InnerLoading";
import {closeEditRoomInspectionItemsView, resetBlock as resetInspectionBlock, setFilters, setRooms, stopInspection, unselectInspectionsGroup} from "features/inspection/inspectionSlice"
import {toast} from 'react-toastify';

import {
  fetchReports,
  fetchRoomSpecs,
  getInspectionResult,
  getSds,
  getSpecificSchool,
} from "../../services/school";

import {
  resetBlockFlags,
  resetBlock as resetSectionsBlock,
} from "../../features/section/sectionSlice";

import {resetBlock as resetSchoolsBlock} from "features/school/schoolSlice";
import {resetBlock as resetCleanerBlock} from "features/cleaner/cleanerSlice";

import NavBar from "../../components/MainNavbar";
import { RoomInspectionItemsForm } from "components/Dashboard/OverviewComponents/RoomInspectionItemsForm";
import AppContainer from "components/AppContainer";

function Overview(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const { loginSuccess: userSuccess, data: userData } = useSelector(
    ({ auth }) => auth
  );
  let role = userData?.user?.role;


  const isSuperAdmin = role === "super_admin";
  const isDistrictAdmin = role === "district_admin";
  const isBuildingAdmin = role === "building_admin";
  const isInspector = role === "inspector";

  const districtId = params.districtId ? params.districtId : location?.state?.districtId;
  const schoolId = params.schoolId ? params.schoolId : location?.state?.schoolId;
  const {
    getSpecificSchool: { success: schoolSuccess, loading: schoolLoading,  data: school = {} },
    editCleaner: { success: editCleanerSuccess, error: editCleanerError  },
  } = useSelector(({ school }) => school);

  const {
    sections: { success: sectionSuccess, data: sections },
    deleteSection: {success: deleteSectionSuccess},
  } = useSelector(({ section }) => section);
  
  const {
    inEditRoomInspectionItemsView: {room: roomInspectionItemsInView},
    inspection: { isInspecting },
  } = useSelector(({ inspection }) => inspection);

  const {
    addCleaner: {success: addCleanerSuccess, error: addCleanerError},
  } = useSelector(({ cleaner }) => cleaner);

  const [showDetails, setShowDetails] = useState(true);
  const [selectedTab, setSelectedTab] = useState("inspections");
  const [selectedSection, setSelectedSection] = useState({});

  useEffect(() => {
    if (!userSuccess) {
      dispatch(getUserDetails());
    }
    //Start without inspecting
    if (isInspecting) {
     dispatch(stopInspection());
    }
    //Reset everything when this is detroyed
    return () => {
      dispatch(resetSchoolsBlock({ blockType: "getSpecificSchool"}));
      dispatch(resetSectionsBlock({ blockType: "sections"}));
      dispatch(resetSchoolsBlock({ blockType: "sds"}));
      dispatch(resetSchoolsBlock({ blockType: "reports"}));
      dispatch(resetSchoolsBlock({ blockType: "room_specs"}));
      dispatch(resetSchoolsBlock({ blockType: "getInspectionResult"}));
      dispatch(resetSchoolsBlock({ blockType: "rooms"}));
      dispatch(resetSchoolsBlock({ blockType: "map"}));

      dispatch(resetInspectionBlock({blockType: 'inspections'}))
      dispatch(resetInspectionBlock({blockType: 'inspectionItems'}))
      dispatch(resetInspectionBlock({blockType: 'itemResults'}))
      dispatch(resetInspectionBlock({blockType: 'itemResultImages'}))
      dispatch(setFilters({cleaners: '', search: '', order: 'recent'}));
      dispatch(unselectInspectionsGroup());

      dispatch(resetCleanerBlock({blockType: 'schoolCleaners'}))
      dispatch(resetCleanerBlock({blockType: 'districtCleaners'}))
      dispatch(resetCleanerBlock({blockType: 'cleaners'}))

      dispatch(stopInspection());
      dispatch(setRooms([]));
      dispatch(closeEditRoomInspectionItemsView());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Select the first section by default
  useEffect(() => {
    if (sectionSuccess && sections.length === 0 ) {
        setSelectedSection({});
    }
    if (!selectedSection?.id && sectionSuccess && sections.length > 0) {
        setSelectedSection(sections[0]);
    }
  }, [sectionSuccess,selectedSection?.id,sections,dispatch]);

  useEffect(() => {
    if (sectionSuccess && deleteSectionSuccess && sections.length > 0 ) {
        setSelectedSection(sections[0]);
        dispatch(resetBlockFlags({blockType: 'deleteSection'}));
    }
  }, [sectionSuccess, deleteSectionSuccess,sections,dispatch]);

  useEffect(() => {
    if (userSuccess) {
      dispatch(getSpecificSchool(schoolId));
    }
  }, [userSuccess,schoolId,dispatch]);

  useEffect(() => {
    if (schoolSuccess) {
      dispatch(fetchSections(schoolId));
      dispatch(getInspectionResult(schoolId));
      dispatch(fetchReports(schoolId));
      dispatch(fetchRoomSpecs(schoolId));
      dispatch(getSds(schoolId));
    }
  }, [schoolSuccess,schoolId, dispatch]);

  useEffect(() => {
    if (editCleanerSuccess) {
      toast.success("Cleaner successfully updated");
    }
  }, [editCleanerSuccess]);

  useEffect(() => {
    if (editCleanerError) {
      toast.error("Cleaner could not be updated");
    }
  }, [editCleanerError]);


  useEffect(() => {
    if (addCleanerSuccess) {
      toast.success("Cleaner successfully added");
    }
  }, [addCleanerSuccess]);

  useEffect(() => {
    if (addCleanerError) {
      toast.error("Cleaner could not be added");
    }
  }, [addCleanerError]);
  
  return (
    <AppContainer className='page-content'>
      <NavBar />
      {roomInspectionItemsInView && (
        <RoomInspectionItemsForm setSelectedTab={setSelectedTab}/>
      )}
      {!roomInspectionItemsInView && (
      <div className="dashboard overview position-relative">
            <InnerLoading show={schoolLoading} />
            {schoolSuccess && school && (
            <OverviewMainContent
              //SelectedBuilding component props
              isInspector={isInspector}
              school={school}
              //Navbar component props
              isSuperAdmin={isSuperAdmin}
              isDistrictAdmin={isDistrictAdmin}
              isBuildingAdmin={isBuildingAdmin}
              setSelectedTab={setSelectedTab}
              //section component props
              selectedTab={selectedTab}
              districtId={districtId}
              sections={sections}
              setShowDetails={setShowDetails}
              showDetails={showDetails}
              selectedSection={selectedSection}
              setSelectedSection={setSelectedSection}
            />
            )}
      
      </div>
      )}
    </AppContainer>
  );
}
export default Overview;
