import { createSlice } from "@reduxjs/toolkit";
import { getInspectionOfDate, getInspectionsGroupRooms, getModelDate } from "utils/inspectionsGroup";
import { BaseServiceReducer, block } from "utils/slices";

const initialState = {
  inspection: {
    inspectedRoom: null, 
    selectedInspection: null, 
    isInspecting: false},
  selectedInspectionsGroup: {data: null},
  defaultInspectionItems: {...block, data: []},
  inspectionItems:  {...block, data: {}},
  itemResults: {...block, data: {}},
  updateItemResults: {...block, data: null},
  itemResultImages: {...block, data: []},
  updateItemResultImages: {...block, data: []},
  deleteItemResultImages: {...block},
  inspections: {...block, data: []},
  createInspection: {...block, data: []},
  updateInspection: {...block, data: []},
  roomTypes: {...block, data: []},
  createRoomType: {...block, data: null},
  updateRoomType: {...block, data: null},
  deleteRoomType: {...block},
  bulkUpdateDefaultItems: {...block, data: []},
  bulkDeleteDefaultItems: {...block},
  bulkUpdateItems: {...block, data: []},
  bulkDeleteItems: {...block},
  inEditRoomInspectionItemsView: {room: null},
  saveInspection: {...block},
  filters: {cleaners: '', order: 'recent', search: ''},
  inspectionsGroupRooms: {data: []},
  deleteInspection: {...block},
};

const sliceName = 'inspection';
const serviceReducer = BaseServiceReducer(sliceName, initialState);
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ...serviceReducer,
    openEditRoomInspectionItemsView(state, action) {
      state.inEditRoomInspectionItemsView.room = action.payload;
    },
    closeEditRoomInspectionItemsView(state) {
      state.inEditRoomInspectionItemsView.room = null;
    },
    selectInspectionsGroup({selectedInspectionsGroup}, {payload}) {
      selectedInspectionsGroup.data = payload;
    },
    unselectInspectionsGroup({selectedInspectionsGroup})  {
      selectedInspectionsGroup.data = null;
    },
    selectInspection({inspection}, {payload})  {
      inspection.selectedInspection = payload;
    },
    unselectInspection({inspection})  {
        inspection.selectedInspection = null;
    },
    startInspection(state, action) {
      state.inspection.inspectedRoom = action.payload;
      state.inspection.isInspecting = true;
    },
    stopInspection(state, action) {
      state.inspection.inspectedRoom = null;
      state.inspection.isInspecting = false;
    },
    fetchInspectionsSuccess({inspections}, action) {
      inspections.data = action.payload;  
      inspections.success = true;
      inspections.loading = false;
    },
    createInspectionSuccess({inspections, createInspection}, action) {
      createInspection.success = true;
      createInspection.loading = false;
      createInspection.data = action.payload; 
      let newData = [...inspections.data, action.payload];
      inspections.data = newData;
    },
    updateInspectionSuccess({inspections, updateInspection}, {payload: updated}) {
      updateInspection.success = true;
      updateInspection.loading = false;
      updateInspection.data = updated;

      inspections.data = inspections.data.map((inspection) => {
        return updated.id === inspection.id ? updated : inspection; 
      });
    },
    fetchRoomTypesSuccess({roomTypes}, {payload: types}) {
      roomTypes.success = true;
      roomTypes.loading = false;
      roomTypes.data = types;
    },
    fetchDefaultInspectionItemsSuccess(state, {payload: {items, roomTypeId}}) {
      state.defaultInspectionItems.success = true;
      state.defaultInspectionItems.loading = false;
      let newData = {...state.defaultInspectionItems.data};
      newData[roomTypeId] = items;
      state.defaultInspectionItems.data = newData;
    },
    fetchDefaultInspectionItemsFailure(state, action) {
      state.defaultInspectionItems.success = false;
      state.defaultInspectionItems.loading = false;
      state.defaultInspectionItems.error = action.payload;
    },
    createRoomTypeSuccess(state, {payload: type}) {
      state.createRoomType.success = true;
      state.createRoomType.loading = false;
      state.createRoomType.data = type;
      const types = state.roomTypes;
      types.data = [...types.data, type];
    },
    updateRoomTypeSuccess(state, {payload: type}) {
      state.updateRoomType.success = true;
      state.updateRoomType.loading = false;
      state.updateRoomType.data = type;
      const types = state.roomTypes;
        types.data = types.data.map((oldType) => {
        return type.id === oldType.id ? type : oldType;
     });
    },
    deleteRoomTypeSuccess(state, {payload: type}) {
      state.deleteRoomType.success = true;
      state.deleteRoomType.loading = false;
      state.roomTypes.data = state.roomTypes.data.filter(({id}) => {
        return type.id !== id;
      })
    },
    bulkUpdateDefaultItemsSuccess({defaultInspectionItems, bulkUpdateDefaultItems}, {payload: {items, roomTypeId}}) {
      bulkUpdateDefaultItems.data = items;
      let newData = {...defaultInspectionItems.data};
      newData[roomTypeId] = items;
      defaultInspectionItems.data = newData;
      bulkUpdateDefaultItems.success = true;
      bulkUpdateDefaultItems.loading = false;
    },
    fetchInspectionItemsSuccess({inspectionItems}, {payload: {items, roomId}}) {
      let newData = {...inspectionItems.data};
      newData[roomId] = items;
      inspectionItems.data = newData;
      inspectionItems.success = true;
      inspectionItems.loading = false;
    },
    bulkUpdateItemsSuccess({inspectionItems, bulkUpdateItems}, {payload: {items, roomId}}) {
      bulkUpdateItems.data = items;
      let newData = {...inspectionItems.data};
      newData[roomId] = items;
      inspectionItems.data = newData;
      bulkUpdateItems.success = true;
      bulkUpdateItems.loading = false;
    },
    fetchItemResultsSuccess({itemResults}, {payload: {items, inspectionId}}) {
      itemResults.success = true;
      itemResults.loading = false;

      let newData = {...itemResults.data};
      newData[inspectionId] = items;
      itemResults.data = newData;
    },
    updateItemResultsSuccess({itemResults, updateItemResults}, {payload: {items, inspectionId}}) {
      updateItemResults.success = true;
      updateItemResults.loading = false;

      let newData = {...itemResults.data};
      newData[inspectionId] = items;
      itemResults.data = newData;
    },
    fetchItemResultImagesSuccess({itemResultImages}, {payload: images}) {
      itemResultImages.success = true;
      itemResultImages.loading = false;
      itemResultImages.data = images;
    },
    updateItemResultImagesSuccess({itemResultImages, updateItemResultImages}, {payload: images}) {
      updateItemResultImages.success = true;
      updateItemResultImages.loading = false;
      updateItemResultImages.data = images;
      itemResultImages.data = [...itemResultImages.data, ...images]
    },
    setFilters(state, {payload}) {
      state.filters = {...state.filters, ...payload};
    },
    setRooms({selectedInspectionsGroup, inspections, inspectionsGroupRooms, filters }, {payload}) {
      let selectedGroup = selectedInspectionsGroup.data;
      let rooms = []; 
      if (selectedGroup) {
        if (selectedGroup.isTodays) {
          rooms = [...payload];
        } else {
          let groupDate = getModelDate(selectedGroup);
          rooms = getInspectionsGroupRooms(inspections.data, payload, groupDate);
        }
      }
      let cleanersFilter = filters.cleaners;
      if (cleanersFilter) {
        rooms = rooms.filter(({cleaner}) => (cleaner == cleanersFilter))
      }
      inspectionsGroupRooms.data = rooms;
    },
    deleteItemResultImagesSucces({itemResultImages, deleteItemResultImages}, {payload: removedImages}) {
      deleteItemResultImages.success = true;
      deleteItemResultImages.loading = false;
      itemResultImages.data = itemResultImages.data.filter(({id}) => {
        return !removedImages.some(({id: removedId}) => id == removedId); 
      })
    },
    deleteInspectionSuccess({
      inspections, 
      deleteInspection, 
    }, {payload: inspectionId}) {
      deleteInspection.success = true;
      deleteInspection.loading = false;
      inspections.data = inspections.data.filter(({id}) => {
        return id !== inspectionId;
      });

    },
  }
});

export const {
  startInspection,
  stopInspection,
  fetchDefaultInspectionItemsSuccess,
  fetchDefaultInspectionItemsFailure,
  setBlockLoadingFlag,
  resetBlockFlags,
  startBlockFlags,
  setBlockSuccessFlags,
  setBlockFailure,
  setBlockSuccess,
  resetBlock,
  fetchRoomTypesSuccess,
  createRoomTypeSuccess,
  updateRoomTypeSuccess,
  deleteRoomTypeSuccess,
  bulkUpdateDefaultItemsSuccess,
  fetchInspectionItemsSuccess,
  bulkUpdateItemsSuccess,
  openEditRoomInspectionItemsView,
  closeEditRoomInspectionItemsView,
  fetchInspectionsSuccess,
  createInspectionSuccess,
  selectInspectionsGroup,
  unselectInspectionsGroup,
  fetchItemResultsSuccess,
  updateItemResultsSuccess,
  updateInspectionSuccess,
  fetchItemResultImagesSuccess,
  updateItemResultImagesSuccess,
  setFilters,
  setRooms,
  deleteItemResultImagesSucces,
  deleteInspectionSuccess,
  selectInspection,
  unselectInspection,
} = slice.actions;
export default slice.reducer;
