import Cookies from "js-cookie";
import moment from "moment";
import { toast } from "react-toastify";
export const isLoggedIn = () => {
  const token = Cookies.get("token");
  return !!token;
};

export const getHeader = () => {
  return { headers: { Authorization: "Basic " + Cookies.get("token") } };
};

export const displayFormattedDateTime = (date) => {
  return moment(date).format("ddd, MM/DD/YYYY h:mm a");
};
export const displayFormattedDate = (date) => {
  return moment(date).format("ddd, MM/DD/YYYY");
};

export const calculateInspectionResult = (result) => {
  if (!result) return "NA";
  let total = result.length;
  if (total < 10) {
    total = `0${total}`;
  }
  const passed = result.filter((r) => r.status === 1);
  let pass = passed.length;
  if (pass < 10) {
    pass = `0${pass}`;
  }
  return `${pass}/${total}`;
};
export const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

export const textIncludes = (t1, t2) => {
  return t1 && t2 && t1.trim().toLowerCase().includes(t2.trim().toLowerCase());
}

export function highlight(text, search) {
    var re = new RegExp('(' + search.split(' ').join('|') + ')', 'gi')
    return text.replace(re, '<span class="highlighted-text">$1</span>')
}
export function zeroPrefixedNumber(n) {
  if (!n) {
    return `00`;
  }
  return n < 10 ? `0${n}` : n; 
}


export const toastMsg = (message, type = 'success', location = 'top-center') => {
  toast[type](message, {
    position: location,
  });
};

export const checkValidEmail=(email)=>{
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
}


export const  convertToHoursAndMinutes=(minutes)=> {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const formattedHours = hours < 10 ? '0' + hours : hours;
  const formattedMinutes = remainingMinutes < 10 ? 0 + remainingMinutes : remainingMinutes;
  return formattedHours + ':' + formattedMinutes.toFixed(2);
}

export const convertToHoursMinutesSeconds = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedHours = hours < 10 ? '0' + hours : hours;
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

  return formattedHours + ':' + formattedMinutes + ':' + formattedSeconds;
}


export const  generateUniqueId=(length=4)=> {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    id += chars[randomIndex];
  }
  return id;
}

export const  sortByProperty=(array, prop, order = 'asc')=> {
  const sortedArray = [...array];
  sortedArray.sort((a, b) => {
      const valA = a[prop];
      const valB = b[prop];
      return order === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA);
  });
  return sortedArray;
}

export const  convertMinutesToSeconds=(minutes)=> {
    return minutes * 60;
}


export const  convertSecondsToMinutes=(minutes)=> {
  return minutes / 60;
}