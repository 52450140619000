import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { resetUserStatus } from "../features/admin/adminSlice";
import { resetBlockSchoolSuccess } from "../features/school/schoolSlice";
import { fetchRoles } from "../services/admin";
import { sendUserInvite } from "../services/auth";
import { fetchDistricts } from "../services/District";
import { fetchSchools } from "../services/school";
import "./InviteUserModal.scss";

import BaseModal from "components/BaseModal";

const InviteUserModal = ({ showModal, handleClose }) => {
  const dispatch = useDispatch();
  const {
    data: rolesData,
    success: rolesSuccess,
    error: rolesError,
  } = useSelector(({ admin }) => admin.roles);
  const { data: districts, success } = useSelector(
    ({ district }) => district.districts
  );
  const {
    data: updateData,
    success: updateSuccess,
    error: InviteError,
    loading: updateLoading,
  } = useSelector(({ admin }) => admin.updateUser);
  const { data: schools, success: schoolsSuccess } = useSelector(
    ({ school }) => school.schools
  );
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    district_id: "",
    school_ids: [],
  });

  // reset modal
  useEffect(() => {
    if (!rolesSuccess) {
      dispatch(fetchRoles());
    }
    dispatch(fetchDistricts());
    dispatch(resetUserStatus());
    setFormData({
      name: "",
      email: "",
      role: "",
      district_id: "",
      school_ids: [],
    });
    setFormErrors({
      name: false,
      email: false,
      role: false,
      district_id: false,
      school_ids: false,
    });
    setShowErrorMessage(false);
    setErrorMessage("");
  }, [showModal]);

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    role: false,
    district_id: false,
    school_ids: false,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "district_id") {
      dispatch(resetBlockSchoolSuccess({ blockType: "schools" }));
      dispatch(fetchSchools(value));
    }
    if (name === "school_ids") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: Array.from(
          event.target.selectedOptions,
          (option) => option.value
        ),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let errors = false;
    const newFormErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key] && key !== "school_ids") {
        errors = true;
        newFormErrors[key] = true;
      } else {
        newFormErrors[key] = false;
      }
    });
    setErrorMessage('');
    setFormErrors(newFormErrors);
    if (!errors) {
      dispatch(sendUserInvite(formData));
    }
  };

  useEffect(() => {
    if (InviteError) {
      setErrorMessage(`${updateData}`);
      setShowErrorMessage(true);
    } else {
      dispatch(resetUserStatus());
      handleClose();
    }
  }, [InviteError, updateSuccess]);

  const roleOptions = rolesData?.roles || [];
  const districtOptions = districts.map((district) => {
    return { value: district.id, label: district.name };
  });

  const schoolOptions = schools.map((school) => {
    return { value: school.id, label: school.name };
  });

  return (
    <BaseModal
      onSubmit={handleSubmit}
      onClose={handleClose}
      show={showModal}
      title="Invite User"
      formLoading={updateLoading}
      formWrapper={true}
      content={
        <>
          {showErrorMessage && (
            <MessageBox>
              <p>{errorMessage}</p>
            </MessageBox>
          )}
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="John Doe"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              className="mb-0"
            />
            {formErrors.name && (
              <Form.Text className="text-danger">
                Field Name is required.
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="formEmail" className="mt-2">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="johndoe@mail.com"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="mb-0"
              required
            />
            {formErrors.email && (
              <Form.Text className="text-danger">
                Field Email is required.
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label>Select Role</Form.Label>
            <Form.Control
              as="select"
              name="role"
              placeholder="User Role"
              value={formData.role}
              onChange={handleInputChange}
              className="mb-0"
              required
            >
              <option value={null}>
                <span className="text-muted">select role</span>
              </option>
              {roleOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
            {formErrors.role && (
              <Form.Text className="text-danger">
                Field Role is required.
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label>Select District</Form.Label>
            <Form.Control
              as="select"
              name="district_id"
              value={formData.district_id}
              onChange={handleInputChange}
              className="mb-0"
              required
            >
              <option value={null}> select district </option>
              {districtOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
            {formErrors.district_id && (
              <Form.Text className="text-danger">
                Field District is required.
              </Form.Text>
            )}
          </Form.Group>
          {formData.role === "inspector" && (
            <Form.Group className="mt-2">
              <Form.Label>Select Schools</Form.Label>
              <Form.Control
                as="select"
                name="school_ids"
                multiple={true}
                value={formData.school_ids}
                onChange={handleInputChange}
                className="mb-0"
              >
                {schoolOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
              {formErrors.school_ids && (
                <Form.Text className="text-danger">
                  Field school is required.
                </Form.Text>
              )}
            </Form.Group>
          )}
        </>
      }
    />
  );
};
const MessageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffe;
  color: red;
  margin: 10px;
`;

export default InviteUserModal;
